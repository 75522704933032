@import 'vars.scss';

.frame {
    position: relative;
    text-align: center;
    
    .inframeWrp {
        position: absolute;
        max-width: 100%;
        overflow: hidden;
    }
    
    &.macbookair {
        .inframeWrp {
            width: 68.5%;
            left: 16%;
            height: 81%;
            top: 5%;
            display: flex;
            background: black;
        }
    }
    &.ipad {
        .inframeWrp {
            width: 86.5%;
            left: 6.6%;
            top: 4.8%;
            height: 90%;
        }
    }
    &.iphonex {
        .frameImg {
            width: 75%;
            margin: 0 auto
        }
        .inframeWrp {
            width: 63.5%;
            left: 18.3%;
            top: 3%;
            border-radius: 3rem;
            height: 93%
        }
        
        @media (max-width: $break-small) {
            .frameImg {
                width: 75%;
                margin: 0 auto
            }
        }
    }
    img, video {
        width: 100%;
    }
    iframe {
        height: 100%;
        width: 100%
    }
}

.phoneWrp {
    position: relative;
    
    .iframeWrp {
        margin: 0 auto;
        position: absolute;
        top: 15px;
        left: 24px;
        border-radius: 33px;
        overflow: hidden;
        width: 86%;
        height: 94%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &> div {
            height: 2rem;
            background: #000
        }
    }
    
    img {
        height: 60rem;
    }
    
    .iframeWrp img {
        width: 100%;
        height: auto
    }
    
    iframe {
        width: 28.8rem;
        display: block;
        height: 54.7rem;
    }
}