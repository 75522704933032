@import 'vars.scss';

.product {
    
    .frameWrp {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 0 2rem;
        
        &.col:first-child {
            padding-top: 3rem;
        }
    }
    
    
    .doublecol   {
        .mainWrp {
            display: flex;
            justify-content: space-between;
            
            & > .col {
                display: flex;
                justify-content: space-between;
                width: 50%;
                float: left;
                flex-flow: column;
                padding: 0 4%;
                
                &:first-child {
                    border-right: 1px solid $maincol;
                    
                }
                
            }
            
        }
        
    }
    
    
    .details {
        padding-top: 0
    }
    
    
    @media (max-width: $break-small) {
        
        .frameWrp {
            flex-flow: column;
            margin-top: 4rem;
        }
        
        .frame {
            padding-top: 0
        }
    
        .doublecol .mainWrp {
            display: block;
        
            & > .col {
                display: block;
                width: 100%;
                float: none;
                &:first-child {
                    border: 0
                
                }
            }
        }
        
    }
    
    
    .col {
        width: 50%;
        float: left
    }
    
    .padLeft {
        padding-left: 6rem;
        
        h2 {
            font-size: 3rem;
            text-align: left;
            line-height: 3.8rem
        }
    }
    
    .features {
        
        display: block;
        
        h2 {
            text-align: center;
            max-width: none
        }
        
        h3 {
            font-size: 2.5rem;
            line-height: 3rem
        }
        h3 i {
            font-size: 2.2rem;
            margin-right: .5rem;
            //color: $maincol
        }
        p {
            font-size: 1.8rem;
            line-height: 2.2rem
        }
        .mainWrp > .col {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            float: none;
        }
        .mainWrp {
            overflow: hidden
        }
        .mainWrp > .col.allalone {
            margin: 0 auto;
            float: none
        }
        .col {
            width: 50%;
            h3 {
                text-align: left
            }
            .col {
                padding-left: 4rem;
            }
        }
        .col.first {
            padding-left: 4rem;
            
        }
        
        @media (max-width: $break-mid) {
            
            .mainWrp {
                margin-bottom: 0;
                
                h3 {
                    text-align: left
                }
                
            }
            
        }
        
        @media (max-width: $break-small) {
            
            .mainWrp .col {
                display: block;
                width: 100%;
            }
            .col .col {
                padding-left: 0
            }
            
        }
    }
    
    @media (max-width: $break-small) {
        .col {
            width: 100%;
            float: none;
        }
        .padLeft {
            padding-left: 0;
            margin-top: 2rem;
            
            h2 {
                text-align: center;
                padding: 0 4rem;
            }
        }
        section.body.center {
            padding-bottom: 0
        }
    }
}