@import 'vars.scss';
@import 'frames.scss';
@import 'products.scss';
@import 'tables.scss';
/* removing animate.css as I can't use it with css modules:
https://gravitydept.com/blog/keyframe-animations-in-css-modules
 */
@import 'animate.scss';

@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://static.Querlo.com/fonts/fontawesome5/css/all.css');

/*
@font-face {
	font-family: 'futuraMediumBT';
	src: url('./src/assets/fonts/futuraMediumBT.eot');
	src: local('futuraMediumBT'), url('src/assets/fonts/futuraMediumBT.woff') format('woff'), url('./assets/fonts/futuraMediumBT.ttf') format('truetype');
}
*/

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, menu, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	vertical-align: baseline;
	background: transparent;
}
svg path, svg g {
	fill: inherit;
}
article, aside, figure, footer, header, nav, section {
	display: block
}

input, select {
	vertical-align: middle
}

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

/* NOTE
html is set to 62.5% so that all the REM measurements throughout Skeleton
are based on 10px sizing. So basically 1.5rem = 15px :) */
html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 62.5%
}

html, body, #appWrp {
	-ms-overflow-style: none;
	height: 100%;
	width: 100%;
}
body {
	font-family: $bodyFont;
	font-size: 1.5em; /* currently ems cause chrome bug misinterpreting rems on body element */
	line-height: 1.6;
	font-weight: 300;
	color: $mainFontCol;
	display: flex;
	flex-direction: column;
	background: #fff;
}

.f { display: flex }

.fr { flex-flow: row !important }
.fc { flex-flow: column !important }

.jc-c { justify-content: center }
.jc-top { justify-content: flex-start }
.jc-sa { justify-content: space-around }
.jc-sb { justify-content: space-between }

.ai-c { align-items: center }
.ai-s { align-items: stretch }

.f-grow {flex: 1}

small {
	font-weight: 400;
	font-size: 75%;
	color: #798892;
}

hr {
	width: 100%;
	border: 0;
	border-top: 1px solid #f1f1f1;
}

button, .butt {
	transition: all .3s;
	border: 0;
	padding: .5rem 1rem;
	font-size: 1.5rem;
	background: $maincol;
	color: #fff !important;
	cursor: pointer;
	text-decoration: none;
	border-radius: 3rem;
	
	i {
		margin-right: .5rem
	}
	
	&:hover {
		color: rgba(255,255,255,.8);
		background: lighten($maincol, 10%)
	}
	&[disabled] {
		background: #888;
		cursor: not-allowed;
	}
}
.butt.secondary {
	border: 1px solid #ccc;
	background: #fff;
	color: $mainFontCol !important;
	
	&:hover {
		border-color: darken(#ccc, 10%);
	}
}
.butt.huge, button.huge {
	color: #fff;
	text-transform: capitalize;
	padding: 1.7rem 4rem;
	line-height: 2.1rem;
	letter-spacing: 1px;
	font-size: 2rem;
	min-width: 27rem;
	display: inline-block;
	
	&:hover {
		transform: scale(1.05);
	}
}

h1,h2,h3,h4 {
	font-weight: 400;
	text-align: center
}

h1 {
	font-family: $titlesFont;
	font-size: 6rem;
	line-height: 6.6rem;
	margin-bottom: 6rem;
	text-align: center;
	
	@media (max-width: $break-small) {
		font-size: 3.8rem;
		line-height: 4.2rem;
	}
}
h2 {
	font-family: $titlesFont;
	font-size: 4rem;
	line-height: 4.5rem;
	margin-bottom: 3.2rem;
	
	@media (max-width: $break-small) {
		font-size: 3.5rem;
		line-height: 4rem;
	}
}
h2 i {
	font-size: 3.4rem
}
h2.content, .full p, p {
	font-family: $bodyFont;
	color: $secondaryFontCol;
	font-size: 2.5rem;
	line-height: 3rem;
	margin: 2.4rem 0;
	font-weight: 300;
	text-align: center;
}
.full a, .full i {
	color: #fff
}
.full a:hover {
	color: #fff;
	text-decoration: underline
}
.full.grey i {
	color: #4A4A4A;
}
h3 {
	font-size: 3.4rem;
	line-height: 4rem;
	margin: 0;
	
	i {
		width: 3rem;
		text-align: center;
	}
	
	@media (max-width: $break-small) {
		font-size: 3rem;
		line-height: 3.8rem;
	}
}
h4 {
	font-size: 2.4rem;
	line-height: 3rem;
	margin: 0;
	
	@media (max-width: $break-small) {
		font-size: 2rem;
		line-height: 2.8rem;
	}
}
.calltoaction {
	margin: 0 auto
}
.calltoaction a:first-child {
	margin: 0 2rem 0 0;
	text-align: center;
	
	@media (max-width: $break-small) {
		margin: 0 0 2rem 0
	}
}
.calltoaction a:last-child {
	margin: 0
}
#appWrp.digitaltwin {
	overflow: hidden;
	
	footer {
		display: none;
	}
	header {
		background-color: rgba(255,255,255,.1) !important;
		padding: 1rem 1.4rem;
		a {
			color: #fff;
			font-size: 1.3rem
		}
		li li a {
			color: #4A4A4A !important;
		}
		.logo {
			img {
				height: 3.5rem;
			}
			div {
				display: none;
			}
		}
	}
	main {
		padding-top: 0;
		height: 100%;
	}
}

@media (max-width: $break-small) {
	#appWrp.digitaltwin {
		header a {
			color: #4A4A4A
		}
	}
}

a {
	text-decoration: none;
	transition: all .3s;
	color: $mainFontCol;
	
	&:hover {
		color: $maincol;
	}
}

.mainWrp {
	width: 100%;
	max-width: 110rem;
	margin: 0 auto;
}
main {
	padding-top: 10rem;
	min-height: calc(100vh - 19rem);
	
	&>div {
		-webkit-animation-duration: .3s;
		animation-duration: .3s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeIn;
		animation-name: fadeIn;
	}
}

p, ul, ol {
	font-size: 1.6rem;
	line-height: 2.8rem;
	text-align: left;
	margin-bottom:1.5rem;
	list-style: none;
}
ol {
	list-style: number;
}
.center {
	text-align: center
}.left {
	 text-align: left
 }

section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8rem 2rem;
	overflow: hidden;
	
	@media (max-width: $break-small) {
		padding: 6rem 2rem;
	}
	
	&.cols {
		flex-direction: row;
		justify-content: space-between;
		
		@media (max-width: $break-small) {
			flex-direction: column;
			
			img:first-child {
				margin: 0 0 2rem 0 !important;
			}
			img.right {
				margin: 2rem 0 0 0 !important;
			}
		}
		
		h2 {
			margin-bottom: 6rem
		}
		
		h2, p {
			text-align: left;
		}
		
		img {
			margin-right: 4rem;
			
			&.right {
				margin: 0 0 0 4rem;
			}
		}
	}
	
	p, h1, h2 {
		max-width: 88rem
	}
	h1, h2 {
		margin: 0 auto 4rem auto
	}
	p {
		font-size: 1.8rem;
	}
}

.art {
	width: 100%
}

.full {
	color: #fff;
	background: $backcol;
	
	&.grey {
		background: $grey;
		
		h2, h3, li {
			color: $mainFontCol;
		}
/*		h3 {
			font-size: 3.8rem;
			line-height: 4.8rem;
		}*/
		p, a {
			color: $secondaryFontCol;
		}
	}
	
	.butt {
		background: #fff;
		color: $backcol !important;
	}
	
	h2, p {
		color: #fff
	}
}

.withBackground {
	padding:0;
	margin-top: 4rem;
	background-repeat: no-repeat;
	background-color: #000;
	background-position: 50% 50%;
	background-size: cover;
	
	h1, h2 {
		margin: 0 auto
	}
	
	.titleWrp {
		background: rgba(0,0,0,.8);
		width: 100%;
		padding: 2rem;
		margin-top: 8rem;
		text-align: center
	}
}


.imgHolder {
	overflow: hidden;
	
	img {
		width: 100%;
		max-width: 750px;
		display: block;
		margin: 0 auto;
	}
	@media (max-width: $break-small) {
		margin-left: -26%;
		width: 155%;
		
		&.full {
			width: 146%;
			margin-left: 0;
		}
	}
	
}
.chatWrp {
	display: flex;
	justify-content: center;
	overflow: hidden;

	.chatInline {
		display: block;
		flex: 1;
		max-width: 80rem;
		height: 55rem;
		border-radius: 7px;
		overflow: hidden;
	}
}

blockquote{
	font-size: 1.4em;
	width:60%;
	margin:50px auto;
	font-style:italic;
	color: #555;
	padding:1.2em 30px 1.2em 75px;
	border-left:8px solid $maincol;
	line-height:1.6;
	position: relative;
	background: $grey;
	text-align: left;
	
	&::before{
		content: "\201C";
		color:$maincol;
		font-size:5em;
		position: absolute;
		left: 10px;
		top:-10px;
	}
	
	&::after{
		content: '';
	}
	
	span {
		display:block;
		color:#333333;
		font-style: normal;
		font-weight: bold;
		margin-top:1em;
	}
	
	
	@media (max-width: $break-small) {
		width: 90%
	}
}


#querloEmbd.esd {
	.chatWrp {
		overflow: visible;
	}
	.open .openWrp {
		width: 100%
	}
	.header {
		font-weight: 400;
		font-size: 20px !important;
	}
	.message {
		font-weight: 400
	}
	.icon {
		width: 0.8em !important;
	}
}
