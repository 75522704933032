$maincol: #188442;
$backcol: #188442;
$grey: #f5f5f5;
$titlesFont: Roboto, "Helvetica Neue", Arial, sans-serif;
$bodyFont: Lato, "Helvetica Neue", Arial, sans-serif;
$mainFontCol: #4A4A4A;
$secondaryFontCol: #565454;
$break-small: 945px;
$break-mid: 1024px;
$break-micro: 480px;
$break-h-small: 550px;
//golden ratio
$phi: 1.6180339887498948482;
