@import '../../style/vars.scss';

.hamburgher {
	display: none;
	width: 2.8rem;
	height: 2.3rem;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
	
	@media (max-width: $break-small) {
		display: block !important;
	}
	
	.bars {
		display: block;
		position: absolute;
		height: .3rem;
		width: 100%;
		background: #2aa6db;
		border-radius: .2rem;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out
	}
	
	.bars:nth-child(1) { top: 0 }
	.bars:nth-child(2) { top: 1rem }
	.bars:nth-child(3) { top: 2rem }
}


:global(.openMenu) {
	.hamburgher {
		.bars:nth-child(1) {
			top: 1rem;
			-webkit-transform: rotate(135deg);
			-moz-transform: rotate(135deg);
			-o-transform: rotate(135deg);
			transform: rotate(135deg)
		}
		.bars:nth-child(2) {
			opacity: 0;
			left: -3rem
		}
		.bars:nth-child(3) {
			top: 1rem;
			-webkit-transform: rotate(-135deg);
			-moz-transform: rotate(-135deg);
			-o-transform: rotate(-135deg);
			transform: rotate(-135deg)
		}
	}
}