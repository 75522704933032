@import '../../style/vars.scss';

.youtubePopup {
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	
	.closePopup {
		position: fixed;
		top: 1rem;
		right: 1rem;
		background: #0FA0CE;
		padding: 1rem;
		border-radius: 50%;
		border: 0;
		width: 50px;
		height: 50px;
		line-height: 24px;
		font-size: 20px;
		color: #fff;
		cursor: pointer
	}
	
	video {
		max-width: 95%;
		max-height: 95%;
	}
	
	i {
		margin: 0;
		font-size: 2.8rem;
		margin-left: .5rem;
	}
	
}
