@import '../../style/vars.scss';

.chatPopup {
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
	
	& > iframe {
		width: 100%;
		height: 100%;
		max-width: 90rem;
		max-height: 100rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		flex-direction: column;
	}
	
	.closePopup {
		position: fixed;
		top: 1rem;
		right: 1rem;
		background: #0FA0CE;
		padding: 1rem;
		border-radius: 50%;
		border: 0;
		width: 5rem;
		height: 5rem;
		line-height: 2.4rem;
		color: #fff;
		cursor: pointer;
		z-index: 10;
	}
	
	i {
		margin: 0;
		font-size: 2.8rem;
		margin-left: .5rem;
	}
	
	@media (max-width: $break-small) {
		.closePopup {
			width: 3rem;
			height: 3rem;
			top: .7rem;
			left: .7rem;
			padding-top: .4rem;
		}
		i {
			font-size: 2.2rem;
			margin-left: -.2rem;
		}
	}
}