@import '../../style/vars.scss';

.cookie {
	color: $maincol
}
.choice {
	margin: 2rem 0 !important;
	button {
		margin: 0 1rem
	}
	button:first-child {
		font-size: 2rem;
		padding: 1rem 3rem;
	}
}

.cookiePopup {
	
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	background: rgba(4, 155, 222, .6);
	display: flex;
	justify-content: center;
	align-items: center;
	
	h3 {
		color: $maincol;
		margin-bottom: 4rem
	}
	
	& > div {
		background: #fff;
		position: relative;
		max-width: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		flex-direction: column;
		padding: 2rem;
		box-shadow: 0 0 10px 0 rgba(0,0,0,.15), 0 0 30px 0 rgba(0,0,0,.3);
		
	}
	
	p {
		font-size: 1.4rem;
		line-height: 2rem;
		margin: 0 0 1rem 0;
		
		a {
			text-decoration: underline;
			color: $maincol
		}
	}
	
	.rocket {
		margin: 0 0 2rem 0;
		color: #f8316f;
		font-size: 9rem;
	}
	
	.closePopup {
		position: absolute;
		top: 0;
		right: 1rem;
		padding: 1rem;
		border: 0;
		width: 3rem;
		height: 3rem;
		cursor: pointer;
		
		i {
			margin: 0 0 0 .2rem; /* don't know why I need this margin to center the icon */
			font-size: 2rem;
		}
	}
	
	
	@media (max-width: $break-small) {
		
		align-items: flex-end;
		
		& > div {
			width: 100%;
			max-width: none;
		}
	}
	
}
